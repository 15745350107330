import React from 'react'
import './FAQs.css'; 
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap'
import BookConsultation from '../../Components/BookConsultation/BookConsultation'
import FaqBnrImg from "../../images/faq-bnr-vector.png";
import FaqRightImg from "../../images/faqs-right-img.jpg";
import { Helmet } from "react-helmet-async";

export default function FAQs() {
  return (
    <>
     <Helmet>
      <title>FAQs - Two 2 Tango | Your Questions Answered</title>
      <meta name="description" content="Find answers to common questions about Two 2 Tango's matchmaking process, membership, events, and more. Explore how we help build meaningful connections." />
    </Helmet>
        <section className='faqs-bnr'>
            <Container>
                <Row>
                    <Col lg={7} md={12} className='faqs-bnr-left-col'>
                        <h2 data-aos="zoom-in" data-aos-duration="1000">Frequently Asked Questions</h2> 
                    </Col>
                    <Col lg={5} md={12} className='faqs-bnr-right-col'>
                        <img src={FaqBnrImg} alt="Tow To Tango" />
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section> 
        <section className='faqs-content'>
            <Container>
                <Row>
                    <Col lg={8} md={12} className='faqs-content-left-col'>
                        <div className='faqs-accordian'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" data-aos="zoom-in" data-aos-duration="500">
                                    <Accordion.Header>What is Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    At Two 2 Tango, we aspire to be the largest global community of eligible singles at various stages of life, whether seeking a romantic relationship or looking for a deep platonic connection.
<br /><br />
We create an environment where our members inspire, heal, challenge, and support one another, embracing the diversity each person brings. At the heart of it all, what makes us truly unique is the sense of “belonging” our members cultivate—one of the most powerful human emotions. What sets us apart is intent; all our members are looking to make connections, platonic or romantic. 
<br /><br />
While there is no fee to join our community, we do have an eligibility criteria that must be met.<br /><br />
Our bespoke matchmaking service, available for a fee, is designed for those looking to build meaningful relationships beyond the online world.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Which cities are you based in? </Accordion.Header>
                                    <Accordion.Body>
                                    Our community is global and open for all eligible singles to join. <br />
                                    Our personalised matchmaking service is fee-based and currently available in New York, San Francisco, London, Dubai, New Delhi, Mumbai, Singapore and Hong Kong 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What are the services you offer?</Accordion.Header>
                                    <Accordion.Body>
                                    Joining Two 2 Tango gives you access to a global community of like-minded singles. Enjoy priority access to curated events and experiences, become part of a thoughtfully selected dating pool, and benefit from our personalised matchmaking service. Our expert matchmakers facilitate introductions only when mutual interest is confirmed, ensuring every connection is meaningful and authentic.
                                    <br /><br />
                                    For those seeking a romantic relationship, we also offer a <i>Pay-Per-Introduction</i> personalised matchmaking service. We take time to understand you and suggest matches based on shared interests, values, and relationship goals, ensuring that every connection is meaningful and tailored to what you’re looking for in a partner. 

                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Can anyone join?</Accordion.Header>
                                    <Accordion.Body>
                                    Individuals expressing interest in joining the community undergo a verification process based on eligibility criteria. Only after this review are they invited to become members. 
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                {/* <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How can I connect with the community that I am interested in?</Accordion.Header>
                                    <Accordion.Body>
                                    As a member of the community, you can join groups based on your interests, allowing you to connect and engage with like-minded individuals from diverse backgrounds across the globe.
                                    <br /><br />
                                    We also organise engaging in-person events and informative webinar sessions on a wide range of topics, allowing you to sign up for any events that pique your interest. With access to events worldwide, you will have countless opportunities to connect, learn, and grow within our vibrant community.
                                    </Accordion.Body>
                                </Accordion.Item>  */}
                                
                                <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How does your personalised matchmaking service work?</Accordion.Header>
                                    <Accordion.Body>
                                    Once you opt for our <i>Pay-Per-Introduction</i> service, our dedicated team of matchmakers invest time in getting to know you, in addition to a comprehensive questionnaire that you fill out. Your interests, values, and life experiences play a strong role in helping the team identify and share a potential match. If the match doesn’t work out, the search continues until we find the connection you’re looking for. Once there’s a mutual interest, we’re happy to introduce both of you for an introduction fee.

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How do I get started with Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    Join our community by filling out a questionnaire so we can get to know you better. This will be followed by a short call with the team. Once you qualify, you will be invited to join the community. There is no fee to join the community.
                                    {/* <br /><br />
If you're looking for personalised matchmaking, simply complete our Sign Up form. We will reach out so you can explore what we offer and assess if it’s the right fit for you. */}

                                    </Accordion.Body>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="6-1" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What is the fee per introduction?</Accordion.Header>
                                    <Accordion.Body>
                                    You can contact our team for detailed pricing information, and they’ll be happy to guide you through the options. 
                                    </Accordion.Body>
                                </Accordion.Item> 
                                <Accordion.Item eventKey="8" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Does Two 2 Tango have an eligibility criterion?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, we do. We are focused on building a community where quality and intent take centre stage. As a result, our eligibility criteria consist of both tangible and intangible criteria.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="9" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How do I know if this service is right for me?</Accordion.Header>
                                    <Accordion.Body>
                                    Our service is designed for individuals who are:
                                    <ListGroup  data-aos="fade-up" data-aos-duration="1000">
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="500"
                                            >Looking for something credible and believe in the value add that curation brings.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1000"
                                            >Tired of swiping endlessly on dating apps or feel like they're not making progress in their search.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1500"
                                            >Wanting to get back into testing the waters but unsure of how or where to start.</ListGroup.Item> 
                                        </ListGroup>   
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                {/* <Accordion.Item eventKey="10" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How are matches selected?</Accordion.Header>
                                    <Accordion.Body>
                                    Matches are selected based on a combination of your preferences and our matchmakers' expertise. We consider factors such as shared interests, values, and life goals to ensure compatibility. Our goal is to present you with the most suitable potential partners for offline meetings.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                {/* <Accordion.Item eventKey="11" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How long before I get the first profile?</Accordion.Header>
                                    <Accordion.Body>
                                    The matchmaking duration varies based on preferences, location, and availability. Rest assured, we’re as eager for the meet-cute stories and first encounters to happen as you are! 
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="12" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What happens during introductory meetings?</Accordion.Header>
                                    <Accordion.Body>
                                    Introductions are private and relaxed meetings arranged at a mutually agreed-upon location or on video call. They provide an opportunity to get to know your match on a deeper level and explore the potential for a meaningful relationship.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="13" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Why should I choose an offline matchmaking service?</Accordion.Header>
                                    <Accordion.Body> 
                                    There are many reasons for choosing an offline service. Some value a personalised service that safeguards their privacy. Some like the hand-holding, but almost everyone values the work that goes into building a community of eligible singles.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="14" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How do I know if this community and service are right for me?</Accordion.Header>
                                    <Accordion.Body>
                                    Talk to us and decide!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What is the confidentiality policy at Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    Access to our member profiles are limited to the matchmakers, our globally approved collaborators and to potential matches. After all, it takes Two 2 Tango!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Is there a refund policy?</Accordion.Header>
                                    <Accordion.Body>
                                    We have a no-refund policy. Please read our terms and conditions before signing up.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What payment methods are accepted?</Accordion.Header>
                                    <Accordion.Body>
                                    Our website accepts credit/debit cards and bank transfers. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="18" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Is the subscription automatically renewed?</Accordion.Header>
                                    <Accordion.Body>
                                    International monthly subscriptions are automatically renewed. However, all memberships are a one-time payment and can be renewed by contacting customer support at the end of your membership period.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                {/* <Accordion.Item eventKey="19" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How can I cancel my international subscription?</Accordion.Header>
                                    <Accordion.Body>
                                    You can typically cancel your international subscription through your account settings on the website, or by contacting customer support.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What happens to my data after the end of my membership?</Accordion.Header>
                                    <Accordion.Body>
                                    Your data is usually retained according to the website’s privacy policy, but you can request the deletion of your account and personal information.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Can I switch my plan after signing up?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, you can upgrade your plan through your account settings or by writing to our customer support team at contactus@two2tango.com.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="22" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Do membership benefits apply to mobile apps as well?</Accordion.Header>
                                    <Accordion.Body>
                                    We are currently in the process of developing a mobile app and plan to launch it next year.
                                    </Accordion.Body>
                                </Accordion.Item>  */}
                            </Accordion>
                        </div>
                    </Col>
                    <Col lg={4} md={12} className='faqs-content-right-col'>
                        <div className='faqs-content-right-col-inner'>
                            <img src={FaqRightImg} alt="Tow To Tango" />
                        </div> 
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section> 
    </>
  )
}
