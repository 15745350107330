import React, { useEffect, useState } from "react";
import "./Payment.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { ResolveBaseApiUrl } from "../../utils/Utility";
import axios from "axios";
import Loader from "../../Components/Spinner/Loader";
import { CreateCheckout } from "../../apis/CCAvenueAPI";
import { ValidatePaymentToken } from "../../apis/UserAPI";

export default function CommunityPayment() {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [token, setToken] = useState(searchParam.get("token"));
  const [tokenValid, setTokenValid] = useState(false);
  const [userId, setUserId] = useState("");
  const [pid, setPid] = useState(searchParam.get("pid"));
  const [packages, setPackages] = useState([]);
  const [currency, setCurrency] = useState("");

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [gstAmt, setGSTAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);

  const [updateMsg, setUpdateMsg] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const handleShowMessage = () => setShowMessage(true);
  const handleCloseMessage = () => {
    setShowMessage(false);
    navigate("/mymembership");
  };

  const [disablePayButton, setDisablePayButton] = useState(true);
  const [showButton, setShowButton] = useState({
    stripe: false,
    ccavenue: false,
  });
  const [showLoader, setShowLoader] = useState({
    stripe: false,
    ccavenue: false,
  });

  useEffect(() => {
    if (token !== null && token !== undefined) {
      var tkn = token.replace(/\+/g, "%2B");
      ValidateToken(tkn);
    } else navigate("/Signup");
  }, []);

  const [validatingMsg, setValidatingMsg] = useState("Validating link....");

  const ValidateToken = async (token) => {
    var result = await ValidatePaymentToken(token);
    if (result === "410") {
      setValidatingMsg("Link expired. Please contact admin");
    } else {
      setUserId(result.userId);
      var pkgs = result.package;
      var currs = pkgs[0].currency;
      setPackages(pkgs);
      setCurrency(currs);
      setTokenValid(true);
      if (currs === "INR") setShowButton({ stripe: false, ccavenue: true });
      else setShowButton({ stripe: true, ccavenue: false });
    }
  };

  const handlePackage = (e) => {
    var pkgId = e.target.value;
    setSelectedPackage(pkgId);
    var pkgList = selectedPackage;
    pkgList = [];
    pkgList.push(pkgId);
    calculateAmt(pkgList);
  };

  const calculateAmt = (pkglist) => {
    if (pkglist.length > 0) setDisablePayButton(false);
    else setDisablePayButton(true);

    var ttl = 0;
    pkglist.forEach((p) => {
      var pkg = packages.filter((x) => x.id.toString() === p)[0];
      ttl = ttl + parseInt(pkg.packageValue);
    });

    if (currency === "INR") {
      var gst = ttl * 0.18;
      setGSTAmt(gst.toFixed(2));
      setTotalAmt(ttl + gst);
    } else setTotalAmt(ttl);
  };

  useEffect(() => {
    if (pid != null && packages.length > 0) {
      setSelectedPackage(pid.split(","));
      if (currency === "INR") handleCCAvenueCheckout();
      else if (currency === "USD") handleStripeCheckout();
    }
  }, [packages]);

  const handleStripeCheckout = async () => {
    var pkgId = selectedPackage;
    setShowLoader({ stripe: true, ccavenue: false });
    var url = `${ResolveBaseApiUrl()}Stripe/create-checkout-session?customerId=${encodeURIComponent(
      userId
    )}&packageIds=${pkgId}&type=comm`;

    try {
      const response = await axios.post(url);
      setShowLoader({ stripe: false, ccavenue: false });
      if (response.status === 200) {
        var result = response.data;
        if (result.item1 != null) window.location.href = result.item1;
        else {
          var msg = result.item2;
          switch (result.item2) {
            case "no_change":
              msg = "No change detected in package";
              break;
            case "package_updated":
              msg = "Membership updated successfully!";
              break;
            case "cannot_update_package":
              msg =
                "Membership can not be updated now. Consider downgrade your membership one week before renew is due or after expiry of this membership";
              break;
          }
          setUpdateMsg(msg);
          handleShowMessage();
          setShowLoader((prevState) => ({ ...prevState, stripe: false }));
        }
      } else {
        console.log("Location header not found in the response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCCAvenueCheckout = async () => {
    setShowLoader({ stripe: false, ccavenue: true });
    var pkgId = selectedPackage;
    var result = await CreateCheckout(userId, pkgId, "comm");

    if (result.item1 !== null) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = result.item1.checkoutUrl;

      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "encRequest";
      input.id = "encRequest";
      input.value = result.item1.encryptionRequest;
      form.appendChild(input);

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_code";
      input1.id = "access_code";
      input1.value = result.item1.accessCode;
      form.appendChild(input1);

      document.body.appendChild(form);
      form.submit();
    } else {
      var msg = result.item2;
      switch (result.item2) {
        case "no_change":
          msg = "No change detected in package";
          break;
        case "package_updated":
          msg = "Membership updated successfully!";
          break;
        case "cannot_update_package":
          msg =
            "Membership can not be updated now. Consider downgrading your membership one week before renew is due or after expiry of this membership";
          break;
      }
      setShowLoader((prevState) => ({ ...prevState, ccavenue: false }));
      setUpdateMsg(msg);
      handleShowMessage();
    }
  };

  const handleCheckout = async (e, mode) => {
    if (mode === "in") handleCCAvenueCheckout(e);
    if (mode === "us") handleStripeCheckout(e);
  };

  return (
    <>
      <section className="payment-section">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              {tokenValid ? (
                <div className="payment-section-inner">
                  <div className="payment-section-inner-head">
                    <h2>Packages</h2>
                  </div>
                  {packages.length > 0 &&
                    packages.map((pkg, i) => (
                      <div className="payment-option-selected" key={i}>
                        <Form.Check inline type="radio" id={`pkg-${pkg.id}`}>
                          <Form.Check.Input
                            name="mand-package"
                            type="radio"
                            value={pkg.id}
                            onChange={handlePackage}
                            checked={selectedPackage.includes(
                              pkg.id.toString()
                            )}
                          />
                          <Form.Check.Label>
                            {pkg.packageName}
                            {" ("}
                            <CurrencyFormat
                              value={pkg.packageValue}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currency === "INR" ? "₹ " : "$ "}
                            />
                            {" )"}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    ))}
                  {currency === "INR" ? (
                    <div className="payment-option-selected">
                      <p>
                        GST (18%) -{" "}
                        {
                          <CurrencyFormat
                            value={gstAmt}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency === "INR" ? "₹ " : "$ "}
                          />
                        }
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="payment-grand-total">
                    <h4>Grand Total</h4>
                    <p>
                      <CurrencyFormat
                        value={totalAmt}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency === "INR" ? "₹ " : "$ "}
                      />
                    </p>
                  </div>
                  <form>
                    <div className="payment-paynow">
                      {showButton.stripe ? (
                        <Button
                          type="button"
                          disabled={disablePayButton}
                          onClick={(e) => handleCheckout(e, "us")}
                        >
                          Pay Now
                          <Loader visible={showLoader.stripe} />
                        </Button>
                      ) : showButton.ccavenue ? (
                        <Button
                          type="button"
                          disabled={disablePayButton}
                          onClick={(e) => handleCheckout(e, "in")}
                        >
                          Pay Now
                          <Loader visible={showLoader.ccavenue} />
                        </Button>
                      ) : (
                        " "
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="payment-section-inner">
                  <div className="payment-section-inner-head">
                    <h2>{validatingMsg}</h2>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        className="upgrade-membership-modal"
        show={showMessage}
        onHide={handleCloseMessage}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Title>Update Membership</Modal.Title>
        <Modal.Body>{updateMsg}</Modal.Body>
      </Modal>
    </>
  );
}
