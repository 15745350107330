import axios from "axios";
import store from "../store";
import { logout } from "../slices/authSlice";

class APIService {
  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const token  = store.getState().auth.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  getAuthToken() {
    const state = store.getState().auth.token;
    console.log(state);
    if (state !== undefined) return state.auth.token;
    return ""; // Return an empty string if token is undefined
  }

  //for get method
  async fetchData(endpoint) {
    try {
      const response = await this.axiosInstance.get(endpoint);
      return response.data;
    } catch (error) {
      if (error.response.status === 401)store.dispatch(logout());
      if(error.response.status === 410) return "410";
      else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  }

  async postData(endpoint, data) {
    try {
      const response = await this.axiosInstance.post(endpoint, data);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) store.dispatch(logout());
      else {
        console.error("error posting data:", error);
        throw error;
      }
    }
  }
}

export default APIService;
