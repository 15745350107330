import React, { useEffect, useState } from "react";
import "./SignUp.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import SignupImg1 from "../../images/signup-img1.jpg";
import SignupImg2 from "../../images/signup-img2.jpg";
import SignupImg3 from "../../images/signup-img3.jpg";
import SignupImg4 from "../../images/signup-img4.jpg";
import "react-phone-number-input/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Helmet } from "react-helmet-async";

import {
  EmailExists,
  GetSignUpQ,
  SignupUser,
  VerifyUser,
} from "../../apis/UserAPI";
import Loader from "../../Components/Spinner/Loader";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { GetCities, GetSalaryList } from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";

export default function SignUp() {
  const { location } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [dobValidated, setDobValidated] = useState(true);
  const [salaryList, setSalaryList] = useState([]);

  const validationRegex = {
    firstName: /^[a-zA-Z' -]{2,30}$/,
    lastName: /^[a-zA-Z' -]{2,}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
    age: /^(1[8-9]|[2-6][0-9]|70)$/,
  };

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    dob: "",
    age: "",
    location: "",
    country: "",
    city: "",
    gender: "",
    qna: [],
    captcha: "",
    consent: "",
    tnc: "",
    membershipId: 0,
  });

  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const [validated, setValidated] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [formLoaded, setFormLoaded] = useState(false);

  const updateSignUpData = (key, value) => {
    if (key === "dob") {
      value = ConvertDate(value);
      calculateAge(value);
      setDobValidated(true);
    } else if (key === "consent") {
      if (signUpData.consent === "consented") value = "";
    } else if(key === "tnc"){
      if(signUpData.tnc === "agreed") value = "";
    }
    setSignUpData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const ConvertDate = (value) => {
    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    );
  };

  const updateQnA = (qn, ans, render) => {
    var updatedUserQnA = signUpData.qna;

    const existingQIndex = updatedUserQnA.findIndex((q) => q.id === qn.id);

    if (existingQIndex !== -1) {
      // Update existing question
      const isInitialRender = render === "initial";
      qn.answer = isInitialRender ? qn.answer : ans;
      updatedUserQnA[existingQIndex] = qn;
    } else {
      // Add new question
      updatedUserQnA.push(qn);
    }
    updateSignUpData("qna", updatedUserQnA);
  };

  const getSalaries = async () => {
    var data = await GetSalaryList();
    if (countryCode !== "IN") {
      var salList = data.filter((x) => x.salary_type === "USD");
      setSalaryList(salList);
    } else {
      var salList = data.filter((x) => x.salary_type === "INR");
      setSalaryList(salList);
    }
  };

  function formValid() {
    //console.log(signUpData);
    for (const key in signUpData) {
      // Skip validation for membershipId
      if (key !== "membershipId") {
        // Handle specific validations based on key
        switch (key) {
          case "qna":
            // Validate array of Q&A pairs
            const qna = signUpData[key];
            //console.log(qna);
            for (const qa of qna) {
              if (qa.question !== "Social Media link" && !qa.answer) {
                return false; // Return false if any answer is missing
              }
            }
            break;
          case "emailId":
            // Validate email using regex
            if (
              !validationRegex.email.test(signUpData[key]) ||
              isEmailInvalid
            ) {
              return false; // Return false if email is invalid
            }
            break;
          case "phoneNo":
            // Validate phone number
            if (
              signUpData[key] === "" ||
              isValidPhoneNumber(signUpData[key], countryCode) !== true
            ) {
              handlePhoneValidation();
              return false; // Return false if phone number is invalid
            }
            break;
          case "dob":
            // Validate date of birth
            if (!signUpData[key]) {
              setDobValidated(false); // Set date of birth validation status
              return false; // Return false if date of birth is missing
            } else {
              setDobValidated(true); // Set date of birth validation status
            }
            break;
          case "firstName":
            // Validate first name using regex
            if (!validationRegex.firstName.test(signUpData[key])) {
              return false; // Return false if first name is invalid
            }
            break;
          case "lastName":
            // Validate last name using regex
            if (!validationRegex.lastName.test(signUpData[key])) {
              return false; // Return false if last name is invalid
            }
            break;
          case "age":
            // Validate age using regex
            if (!validationRegex.age.test(signUpData[key])) {
              return false; // Return false if age is invalid
            }
            break;
          default:
            // Validate for any other required fields
            if (!signUpData[key]) {
              return false; // Return false if any other required field is missing
            }
        }
      }
    }

    // If all validations pass, return true
    return true;
  }

  const validateEmail = async () => {
    let emailid = signUpData.emailId;
    if (validationRegex.email.test(emailid)) {
      let uid = await EmailExists(emailid);
      if (uid !== 0) {
        setIsEmailInvalid(true);
        setInvalidEmailMsg("Email already exists!");
      } else {
        setIsEmailInvalid(false);
        setInvalidEmailMsg("");
      }
    } else {
      setIsEmailInvalid(true);
      setInvalidEmailMsg("Invalid email format");
    }
  };

  useEffect(() => {
    const userId = Cookies.get("userid");
    if (userId === undefined || userId === "" || userId === null) {
      loadSignup();
    } else verifyUserId(encodeURIComponent(userId));
  }, [countryCode]);

  useEffect(() => {
    if (alreadySigned) {
      const userId = Cookies.get("userid");
      navigate(`/payment?uid=${encodeURIComponent(userId)}&type=new`);
    } else {
      loadSignup();
    }
  }, [alreadySigned]);

  const loadSignup = () => {
    calculateMaxDate();
    loadCaptchaEnginge(6, "#CDAC45", "white");
  };

  useEffect(() => {
    if (location != null) getCountry();
  }, [location]);

  const getCountry = async () => {
    setCountry(location.country);
    setCountryCode(location.countryCode);
    updateSignUpData("location", location.city + ", " + location.country);
    updateSignUpData("country", location.countryCode);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (country != "" && country != null && country != undefined) {
      getCities();
      getQn();
      getSalaries();
    }
  }, [country]);

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const verifyUserId = async (uid) => {
    var response = await VerifyUser(uid);
    setAlreadySigned(response);
  };

  const getQn = async () => {
    var result = await GetSignUpQ();
    if(result.length > 0) setFormLoaded(true);
    result.forEach((element) => {
      updateQnA(element, null, "initial");
    });
  };

  const calculateMaxDate = () => {
    const today = new Date();
    var mxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    var mnDate = new Date(
      today.getFullYear() - 70,
      today.getMonth(),
      today.getDate()
    );
    setMaxDate(mxDate);
    setMinDate(mnDate);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();

    // Adjust for birth month and day
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    updateSignUpData("age", age);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsInitialLoad(false);

    const form = event.currentTarget;
    let userCaptcha = document.getElementById("captcha").value;
    if (validateCaptcha(userCaptcha) === true && formValid()) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        event.preventDefault();
        var qna = [];
        signUpData.qna.forEach((item) => {
          qna.push({ QuestionId: item.id, Answer: item.answer });
        });
        const userData = {
          firstName: signUpData.firstName,
          lastName: signUpData.lastName,
          dob: signUpData.dob,
          age: signUpData.age + "",
          gender: signUpData.gender,
          userEmail: signUpData.emailId,
          phoneNo: signUpData.phoneNo,
          location: signUpData.location,
          country: signUpData.country,
          city: signUpData.city,
          membershipId: 0,
          qnA: qna,
        };

        setShowLoader(true);
        var id = await SignupUser(userData);
        setShowLoader(false);
        // if (countryCode === "IN") {
           navigate("/thankyou?msg=signup");
        // } else {
        // Cookies.remove("userid");
        // Cookies.set("userid", id, { expires: 7 });

        // navigate(`/payment?uid=${encodeURIComponent(id)}&type=new`);
        //}
      }
    } else {
      updateSignUpData("captcha", "");
    }
    setValidated(false);
  };

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateSignUpData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateSignUpData("city", ""); // Clear city in contactUsData
    }
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(signUpData.phoneNo, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  return (
    <>
    <Helmet>
        <title>Sign Up - Two 2 Tango</title>
        <meta name="description" content="Sign up for Two 2 Tango to access personalized matchmaking and exclusive events. Build real connections beyond apps and algorithms. Join today!" />
      </Helmet>
      <section className="signup-page">
        <Container>
          <Row>
            <Col lg={4} className="signup-form-col-left">
              <div className="signup-form">
                <h2 className="signup-form-heading" data-aos="zoom-in" data-aos-duration="500">
                  Welcome to matchmaking!
                </h2>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Row className="mb-3">
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="FName"
                      label="First Name"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="First Name"
                        value={signUpData.firstName}
                        onChange={(e) =>
                          updateSignUpData("firstName", e.target.value)
                        }
                        isInvalid={
                          !isInitialLoad &&
                          !validationRegex.firstName.test(signUpData.firstName)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid first name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="LName"
                      label="Last Name"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Last Name"
                        value={signUpData.lastName}
                        onChange={(e) =>
                          updateSignUpData("lastName", e.target.value)
                        }
                        isInvalid={
                          !isInitialLoad &&
                          !validationRegex.lastName.test(signUpData.lastName)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid last name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="EmailAddress"
                      label="Email Address"
                    >
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email Address"
                        value={signUpData.emailId}
                        onBlur={validateEmail}
                        onChange={(e) =>
                          updateSignUpData("emailId", e.target.value)
                        }
                        isInvalid={isEmailInvalid}
                      />
                      <Form.Control.Feedback type="invalid">
                        {invalidEmailMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="PhoneNumber"
                      label="Phone Number"
                    >
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryCode}
                        placeholder="Enter phone number"
                        value={signUpData.phoneNo}
                        onChange={(e) => updateSignUpData("phoneNo", e)}
                        rules={{ required: true }}
                        onBlur={handlePhoneValidation}
                      />
                      {!isInitialLoad && !phoneValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid phone number
                        </Form.Text>
                      )}
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="DateOBirth"
                      label="Date of Birth"
                    >
                      <DatePicker
                        value={signUpData.dob}
                        onChange={(e) => updateSignUpData("dob", e)}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        required
                        onInvalidChange={() => setDobValidated(false)}
                      />
                      {!isInitialLoad && !dobValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid dob
                        </Form.Text>
                      )}
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="Age"
                      label="Age"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Age"
                        value={signUpData.age}
                        onChange={(e) =>
                          updateSignUpData("age", e.target.value)
                        }
                        min={18}
                        max={70}
                        isInvalid={
                          signUpData.age === "" && !isInitialLoad ? true : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid age
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="Location"
                      label="Location"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Location"
                        value={signUpData.location}
                        onChange={(e) =>
                          updateSignUpData("location", e.target.value)
                        }
                        isInvalid={signUpData.location == "" ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide location
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="City"
                      label="City"
                    >
                      <Typeahead
                        id="membership_city"
                        labelKey="name"
                        options={cities}
                        placeholder="city living in"
                        selected={selectedCity}
                        isInvalid={
                          (!selectedCity || selectedCity.length == 0) &&
                          !isInitialLoad
                            ? true
                            : false
                        }
                        onChange={handleCityChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide name of the city you live in
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns"
                      controlId="Gender"
                    >
                      <Form.Label>I am a</Form.Label>
                      <div className="radios-btns-gender">
                        <Form.Check
                          inline
                          label="Man"
                          name="Gender"
                          type="radio"
                          id="Male"
                          value="Male"
                          checked={signUpData.gender === "Male"}
                          onChange={(e) =>
                            updateSignUpData("gender", e.target.value)
                          }
                        />
                        <Form.Check inline name="Gender" id="Female">
                          <Form.Check.Input
                            type="radio"
                            value="Female"
                            checked={signUpData.gender === "Female"}
                            onChange={(e) =>
                              updateSignUpData("gender", e.target.value)
                            }
                            isInvalid={
                              !isInitialLoad && signUpData.gender == ""
                            }
                          />
                          <Form.Check.Label>Woman</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            Please fill this field
                          </Form.Control.Feedback>
                        </Form.Check>
                      </div>
                    </Form.Group>
                    {signUpData.qna.length > 0 &&
                      signUpData.qna.map((ques) =>
                        ques.field_type === "radio_text" ? (
                          <Form.Group
                            as={Col}
                            md="12"
                            className="radios-btns"
                            controlId={ques.id}
                            data-aos="fade-up"
                            data-aos-duration="2500"
                            key={ques.id}
                          >
                            <Form.Label>{ques.question}</Form.Label>
                            <div className="radios-btns-gender">
                              {ques.option !== null &&
                                ques.option.split("~").map((opt, indx) => (
                                  <div key={`${ques.id}${indx}`}>
                                    {ques.option.split("~").length - 1 ===
                                    indx ? (
                                      <Form.Check
                                        inline
                                        name={`qo${indx}${ques.id}`}
                                        id={`${indx}${ques.id}`}
                                      >
                                        <Form.Check.Input
                                          type="radio"
                                          value={opt}
                                          checked={
                                            (ques.answer != null &&
                                              ques.answer == opt) ||
                                            (ques.answer != null &&
                                              ques.answer.indexOf("^") !== -1 &&
                                              ques.answer.indexOf(
                                                opt.trim()
                                              ) !== -1)
                                          }
                                          onChange={(e) =>
                                            updateQnA(
                                              ques,
                                              e.target.value,
                                              null
                                            )
                                          }
                                          isInvalid={
                                            !isInitialLoad && ques.answer === ""
                                          }
                                        />
                                        <Form.Check.Label>
                                          {opt}
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                          Please fill this field
                                        </Form.Control.Feedback>
                                      </Form.Check>
                                    ) : (
                                      <Form.Check
                                        inline
                                        name={`qo${indx}${ques.id}`}
                                        id={`${indx}${ques.id}`}
                                      >
                                        <Form.Check.Input
                                          type="radio"
                                          value={opt}
                                          checked={
                                            (ques.answer != null &&
                                              ques.answer == opt) ||
                                            (ques.answer != null &&
                                              ques.answer.indexOf("^") !== -1 &&
                                              ques.answer.indexOf(
                                                opt.trim()
                                              ) !== -1)
                                          }
                                          onChange={(e) =>
                                            updateQnA(
                                              ques,
                                              e.target.value,
                                              null
                                            )
                                          }
                                        />
                                        <Form.Check.Label>
                                          {opt}
                                        </Form.Check.Label>
                                      </Form.Check>
                                    )}
                                    {opt.trim() === "Other" &&
                                      ques.answer != null &&
                                      ques.answer.indexOf("Other") !== -1 && (
                                        <>
                                          <Form.Control
                                            key={`QT_${indx}_${ques.id}`}
                                            type="text"
                                            placeholder={ques.question}
                                            value={
                                              ques.answer == null
                                                ? ""
                                                : ques.answer.split("^")[1]
                                            }
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                "Other^" + e.target.value,
                                                null
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                  </div>
                                ))}
                            </div>
                          </Form.Group>
                        ) : ques.field_type === "radio" ? (
                          <Form.Group
                            as={Col}
                            md="12"
                            className="radios-btns"
                            controlId={ques.id}
                            data-aos="fade-up"
                            data-aos-duration="2500"
                            key={ques.id}
                          >
                            <Form.Label>{ques.question}</Form.Label>
                            <div className="radios-btns-gender">
                              {ques.option !== null &&
                                ques.option.split("~").map((o, i) => (
                                  <div key={`${ques.id}${i}`}>
                                    {ques.option.split("~").length - 1 === i ? (
                                      <Form.Check
                                        inline
                                        name={`qo${i}${ques.id}`}
                                        id={`${i}${ques.id}`}
                                      >
                                        <Form.Check.Input
                                          type="radio"
                                          value={o}
                                          checked={
                                            ques.answer != null &&
                                            ques.answer == o
                                          }
                                          onChange={(e) =>
                                            updateQnA(ques, e.target.value)
                                          }
                                          isInvalid={
                                            !isInitialLoad && ques.answer === ""
                                          }
                                        />
                                        <Form.Check.Label>{o}</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                          Please fill this field
                                        </Form.Control.Feedback>
                                      </Form.Check>
                                    ) : (
                                      <Form.Check
                                        inline
                                        name={`qo${i}${ques.id}`}
                                        id={`${i}${ques.id}`}
                                      >
                                        <Form.Check.Input
                                          type="radio"
                                          value={o}
                                          checked={
                                            ques.answer != null &&
                                            ques.answer == o
                                          }
                                          onChange={(e) =>
                                            updateQnA(ques, e.target.value)
                                          }
                                        />
                                        <Form.Check.Label>{o}</Form.Check.Label>
                                      </Form.Check>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </Form.Group>
                        ) : ques.field_type === "text" ? (
                          <FloatingLabel
                            as={Col}
                            md="12"
                            controlId={`Q_${ques.id}`}
                            label={ques.question}
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            key={ques.id}
                          >
                            <Form.Control
                              type="text"
                              placeholder={ques.question}
                              value={ques.answer ?? ""}
                              onChange={(e) =>
                                updateQnA(ques, e.target.value, null)
                              }
                            />
                          </FloatingLabel>
                        ) : ques.field_type === "select_2" ? (
                          <Form.Group
                            as={Col}
                            md="12"
                            className="radios-btns signup-select-salary"
                            controlId={ques.id}
                            data-aos="fade-up"
                            data-aos-duration="2500"
                            key={ques.id}
                          >
                            <Form.Label column>{ques.question}</Form.Label>
                            <Form.Select
                              className="signup-select"
                              aria-label={ques.question}
                              size="sm"
                              value={ques.answer == null ? "" : ques.answer}
                              onChange={(e) =>
                                updateQnA(ques, e.target.value, null)
                              }
                              isInvalid={!isInitialLoad && ques.answer === ""}
                            >
                              <option key="salary"> </option>
                              {salaryList.map((sal, i) => (
                                <option
                                  value={sal.salary_id}
                                  key={`sal_opt_${i}`}
                                >
                                  {sal.salary_slab}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please fill this field
                            </Form.Control.Feedback>
                          </Form.Group>
                        ) : (
                          ""
                        )
                      )}
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns-gender tnc-checks"
                      controlId="consent-control"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <Form.Check inline name="consent" id="consent">
                        <Form.Check.Input
                          type="checkbox"
                          value="consented"
                          checked={signUpData.consent === "consented"}
                          onChange={(e) =>
                            updateSignUpData("consent", e.target.value)
                          }
                          isInvalid={
                            !isInitialLoad && signUpData.consent === ""
                          }
                          required
                        />
                        <Form.Check.Label>
                          I agree that information I have provided here is true
                          and accurate
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          Please fill this field
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns-gender tnc-checks"
                      controlId="tnc"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <Form.Check inline name="tnc" id="tnc">
                        <Form.Check.Input
                          type="checkbox"
                          value="agreed"
                          checked={signUpData.tnc === "agreed"}
                          onChange={(e) =>
                            updateSignUpData("tnc", e.target.value)
                          }
                          isInvalid={!isInitialLoad && signUpData.tnc === ""}
                          required
                        />
                        <Form.Check.Label>
                          I agree with the {" "}
                          <a
                            href="https://www.two2tango.com/terms-conditions"
                            target="_blank"
                            rel="noreferrer"
                          >
                            terms & conditions 
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://www.two2tango.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacy policy
                          </a>
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          Please fill this field
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="my-membership-inout-row"
                      controlId="captcha"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <Form.Label column sm="12">
                        <LoadCanvasTemplateNoReload />
                      </Form.Label>
                      <Col md="12">
                        <Form.Control
                          type="text"
                          placeholder="captcha"
                          value={signUpData.captcha}
                          onChange={(e) =>
                            updateSignUpData("captcha", e.target.value)
                          }
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Row>
                  <Button
                    className="join-btn"
                    type="submit"
                    disabled={!formLoaded}
                  >
                    JOIN
                    <Loader visible={showLoader} />
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={8}>
              <div className="signup-right-col">
                <div className="signup-img-row">
                  <div className="signup-img-col">
                    <div
                      className="signup-img-col-inner-img"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      <img src={SignupImg1} alt="Tow To Tango" />
                    </div>
                  </div>
                  <div className="signup-img-col signup-img-col-tow-img">
                    <div
                      className="signup-img-col-tow-img-inner"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      <img src={SignupImg2} alt="Tow To Tango" />
                    </div>
                    <div
                      className="signup-img-col-tow-img-inner"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      <img src={SignupImg3} alt="Tow To Tango" />
                    </div>
                  </div>
                </div>
                <div className="signup-img-row">
                  <div className="signup-img-col">
                    <div
                      className="signup-img-col-inner-img"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <img src={SignupImg4} alt="Tow To Tango" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
