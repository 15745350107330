import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/Home/HomePage";
import SignUp from "./Pages/SignUp/SignUp";
import Login from "./Pages/Login/Login";
import Notfound from "./Pages/Notfound/Notfound";
import AboutUs from "./Pages/AboutUs/AboutUs";
import WhyUs from "./Pages/WhyUs/WhyUs";
import Membership from "./Pages/Membership/Membership";
import FAQs from "./Pages/FAQs/FAQs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import HomeLanding from "./Pages/HomeLanding/HomeLanding";
import Notifications from "./Pages/Notifications/Notifications";
import NewMatches from "./Pages/MyMatches/NewMatches";
import Media from "./Pages/Media/Media";
import RelationshipManager from "./Pages/RelationshipManager/RelationshipManager";
import MyMembership from "./Pages/MyMembership/MyMembership";
import UpgradeOptions from "./Pages/UpgradeOptions/UpgradeOptions";
import Referrals from "./Pages/Referrals/Referrals";
import MyProfile from "./Pages/MyProfile/MyProfile";
import NewMatchProfile from "./Pages/MyMatches/NewMatchProfile";
import DeclinedMatchProfile from "./Pages/MyMatches/DeclinedMatchProfile";
import ContactUs from "./Pages/ContactUs/ContactUs";
import FollowUs from "./Pages/FollowUs/FollowUs";
import PrivateRoute from "./PrivateRoute";
import { GetLocalUser } from "./slices/authLocal";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, userLocation } from "./slices/authSlice";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import ChangePassword from "./Pages/Login/ChangePassword";
import Payment from "./Pages/Payment/Payment";
import CreatePassword from "./Pages/Login/CreatePassword";
import ThankYou from "./Pages/ThankYou/ThankYou";
import { GetUserLocation } from "./apis/MiscAPI";
import SixMonthsMembership from "./Pages/Membership/SixMonthsMembership";
import OneYearMembership from "./Pages/Membership/OneYearMembership";
import MonthlyMembership from "./Pages/Membership/MonthlyMembership";
import CCASuccess from "./Pages/Payment/CCASuccess";
import UploadDocuments from "./Pages/MyProfile/UploadDocuments";
import JoinCommunityQuestionnaire from "./Pages/JoinCommunityQuestionnaire/JoinCommunityQuestionnaire";
import CommunityPayment from "./Pages/Payment/CommunityPayment";

function App() {
  const dispatch = useDispatch();
  const { token, location } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
    window.scrollTo(0, 0); // Scroll to top on pathname change
  }, [pathname]);

  const localToken = GetLocalUser().token;
  if (localToken && !token) {
    dispatch(loginSuccess(GetLocalUser()));
  }

  useEffect(() => {
    if (location === null || location.country === null) {
      getLocation(); // Fetch user location if not already available
    }
  }, [location]);

  const getLocation = async () => {
    try {
      const data = await GetUserLocation(); // Example function to get user location
      dispatch(
        userLocation({
          country: data.countryName,
          countryCode: data.countryCode,
          city: data.city,
        })
      ); // Dispatch user location action
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/*" element={<Notfound />} />
        <Route path="/" exact element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/Membership" element={<Membership />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ChangePassword />} />
        <Route path="/createpassword" element={<CreatePassword />} />
        <Route path="/homelanding" element={<HomeLanding />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/media" element={<Media />} />
        <Route path="/relationshipmanager" element={<RelationshipManager />} />

        <Route path="/sixmonthsmembership" element={<SixMonthsMembership />} />
        <Route path="/oneyearmembership" element={<OneYearMembership />} />
        <Route path="/monthlymembership" element={<MonthlyMembership />} />
        <Route path="/upgradeoptions" element={<UpgradeOptions />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/commpayment" element={<CommunityPayment />} />
        <Route path="/paymentstatus" element={<CCASuccess />} />
        <Route path="/join" element={<JoinCommunityQuestionnaire />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route
          path="/myprofile"
          element={
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/changepassword"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/mymatches"
          element={
            <PrivateRoute>
              <NewMatches />
            </PrivateRoute>
          }
        />
        <Route
          path="/uploaddocuments"
          element={
            <PrivateRoute>
              <UploadDocuments />
            </PrivateRoute>
          }
        />
        <Route
          path="/mymembership"
          element={
            <PrivateRoute>
              <MyMembership />
            </PrivateRoute>
          }
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/followus" element={<FollowUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
